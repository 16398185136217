var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main-container d-flex"},[_c('div',{staticClass:"box"},[_c('img',{staticClass:"black-text-logo",attrs:{"src":require("../assets/appreciateLogoHorizontal.png"),"id":"black-text-logo"}}),_c('h2',{staticClass:"fs-5 mt-3"},[_vm._v("Reset Your Password")]),_c('div',{},[(_vm.errorMgs)?_c('div',[_c('h2',{staticStyle:{"color":"red","font-size":"16px"}},[_vm._v(_vm._s(_vm.errorMgs))])]):_c('div',[_c('h2',{staticStyle:{"color":"green","font-size":"16px"}},[_vm._v(_vm._s(_vm.message))])]),_c('form',{attrs:{"novalidate":""},on:{"submit":function($event){$event.preventDefault();return _vm.validateUser.apply(null, arguments)}}},[_c('div',{staticClass:"mt-3"},[_c('md-field',{class:_vm.getValidationClass('password')},[_c('label',[_vm._v("Password")]),_c('md-input',{attrs:{"type":"password","placeholder":"Enter Password","required":""},on:{"keydown":function($event){return _vm.onKeydownMain($event)},"blur":function($event){return _vm.$v.form.password.$touch()},"copy":function($event){$event.preventDefault();},"paste":function($event){$event.preventDefault();}},model:{value:(_vm.form.password),callback:function ($$v) {_vm.$set(_vm.form, "password", $$v)},expression:"form.password"}}),_c('div',{staticClass:"d-block"},[(!_vm.$v.form.password.required)?_c('span',{staticClass:"md-error mb-1"},[_vm._v("Password is required")]):_vm._e()])],1),_c('div',{staticClass:"password-val"},[_c('div',{staticClass:"d-flex",staticStyle:{"align-items":"start","justify-content":"left"}},[_c('div',[_c('img',{staticClass:"check-box-icon",attrs:{"src":_vm.$v.form.password.uppercase && _vm.$v.form.password.lowercase
                    ? require('../assets/circle-check-box.svg')
                    : require('../assets/circle-icon.svg'),"alt":"check-box"}})]),_c('div',[_c('p',{class:_vm.$v.form.password.uppercase && _vm.$v.form.password.lowercase
                    ? 'text-green'
                    : '',staticStyle:{"padding-left":"6px"}},[_vm._v(" Atleast 1 upper & lower case alphabet ")])])]),_c('div',{staticClass:"d-flex",staticStyle:{"align-items":"start","justify-content":"left"}},[_c('div',[_c('img',{staticClass:"check-box-icon",attrs:{"src":_vm.$v.form.password.digits
                    ? require('../assets/circle-check-box.svg')
                    : require('../assets/circle-icon.svg'),"alt":"check-box"}})]),_c('div',[_c('p',{class:_vm.$v.form.password.digits ? 'text-green' : '',staticStyle:{"padding-left":"6px"}},[_vm._v(" Atleast 1 number ")])])]),_c('div',{staticClass:"d-flex",staticStyle:{"align-items":"start","justify-content":"left"}},[_c('div',[_c('img',{staticClass:"check-box-icon",attrs:{"src":_vm.$v.form.password.specialChar
                    ? require('../assets/circle-check-box.svg')
                    : require('../assets/circle-icon.svg'),"alt":"check-box"}})]),_c('div',[_c('p',{class:_vm.$v.form.password.specialChar ? 'text-green' : '',staticStyle:{"padding-left":"6px"}},[_vm._v(" Atleast 1 special character (@,$,!,%,*,#,?,&) ")])])]),_c('div',{staticClass:"d-flex",staticStyle:{"align-items":"start","justify-content":"left"}},[_c('div',[_c('img',{staticClass:"check-box-icon",attrs:{"src":_vm.form.password.length >= 8
                    ? require('../assets/circle-check-box.svg')
                    : require('../assets/circle-icon.svg'),"alt":"check-box"}})]),_c('div',[_c('p',{class:_vm.form.password.length >= 8 ? 'text-green' : '',staticStyle:{"padding-left":"6px"}},[_vm._v(" Minimum of 8 characters ")])])]),_c('div',{staticClass:"d-flex",staticStyle:{"align-items":"start","justify-content":"left"}},[_c('div',[_c('img',{staticClass:"check-box-icon",attrs:{"src":require('../assets/circle-check-box.svg'),"alt":"check-box"}})]),_vm._m(0)])])],1),_c('div',{staticClass:"mb-3 mt-3"},[_c('md-field',{class:_vm.getValidationClass('confirm_password')},[_c('label',[_vm._v("Password")]),_c('md-input',{attrs:{"type":"password","placeholder":"Enter Confirm Password","required":""},on:{"keydown":function($event){return _vm.onKeydownMain($event)},"blur":function($event){return _vm.$v.form.confirm_password.$touch()}},model:{value:(_vm.form.confirm_password),callback:function ($$v) {_vm.$set(_vm.form, "confirm_password", $$v)},expression:"form.confirm_password"}}),(!_vm.$v.form.confirm_password.required)?_c('span',{staticClass:"md-error"},[_vm._v("Confirm password is required")]):(!_vm.$v.form.confirm_password.sameAsPassword)?_c('span',{staticClass:"md-error"},[_vm._v("Password must match")]):_vm._e()],1)],1),_c('md-button',{staticClass:"md-raised md-primary w-100 mx-0",attrs:{"disabled":_vm.$v.$invalid,"type":"submit"}},[_vm._v("Set Password")])],1)])]),_c('div')])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('p',{staticClass:"text-green",staticStyle:{"padding-left":"6px"}},[_vm._v(" Last 3 passwords are restricted ")])])
}]

export { render, staticRenderFns }