<template>
  <div>POA Verification</div>
</template>
<script>
import config from "@/config";
export default {
  data() {
    return {
      message: "",
      errorMgs: "",
      timerCount: 5,
    };
  },
  created() {
    let vm = this;
    axios
      .post(
        `${config?.poaStatus?.url}`, {
            "KycReferenceNumber": config?.poaStatus?.kycRefNo,
            "partnerkey": config?.poaStatus?.partnerKey,
        },
      )
      .then(function (response) {
        vm.message = response.data.message;
      })
      .catch(function (error) {
        vm.errorMgs = error.response.data.message;
        throw error;
      });
  },
  methods: {
  },
};
</script>
