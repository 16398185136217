import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import PasswordReset from '../views/PasswordReset.vue'
import VkycLanding from '../views/vkyc-landing.vue'
import Unsubscribe from '../views/unsubscribe.vue'
import poaVerification from '../views/poa-verification.vue'

Vue.use(VueRouter)

const routes = [{
        path: '/email-verification/:id/:users?/:email_id?',
        name: 'Home',
        component: Home
    },
    {
        path: '/reset_password/:id',
        name: 'PasswordReset',
        component: PasswordReset
    },
    {
        path: '/vkyc/:id',
        name: 'VkycLanding',
        component: VkycLanding
        
    },
    {
        path: '/unsubscribe/:email_id/:group_id',
        name: 'Unsubscribe',
        component: Unsubscribe
    },
    {
        path: '/unsubscribe/sucess',
        name: 'Unsubscribe-success',
        component: Unsubscribe  
    },
    {
        path: '/poa-verification',
        name: 'poa-verfication',
        component: poaVerification  
    }


]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router