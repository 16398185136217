<template>
  <div class="main-container">
    <div class="vkyc-landing">
      <img
        class="vkyc-landing-logo"
        src="../assets/appreciateLogoHorizontal.png"
        alt="appreciate-logo"
      />
    </div>

    <!-- VKYC loading conetent -->
    <section class="vkyc-content" v-if="!kyc_status">
      <h2 class="vkyc-content-heading">YES Bank VKYC Requirements</h2>
      <p>
        You are mandatorily required to complete Video KYC with YES Bank to
        create your Appreciate Premiere Cash Account. Please ensure you meet the
        following requirements before you proceed
      </p>

      <div class="row">
        <div class="col-md-6 col-sm-12 content-list">
          <div>
            <div class="content-bullet"></div>
            You are physically present in India
          </div>
          <div>
            <div class="content-bullet"></div>
            You are present in a well lit room
          </div>
          <div>
            <div class="content-bullet"></div>
            You have a strong internet connection
          </div>
          <div>
            <div class="content-bullet"></div>
            Your device has working camera and microphone features
          </div>
        </div>

        <div class="col-md-6 col-sm-12">
          <div>
            <div class="content-bullet"></div>
            You have a physical copy of your PAN card
          </div>
          <div>
            <div class="content-bullet"></div>
            You have a white paper and pen
          </div>
          <div>
            <div class="content-bullet"></div>
            You know how to share location information from your device
          </div>
        </div>
      </div>
      <div class="mt-3 vkyc-btn">
        <md-button
          @click="checkVKYC()"
          class="md-raised start-button  btn-clr m-0"
          >start VKYC now</md-button
        ><br />
      </div>
      <div class="mt-4 align-left">
        <p class="d-inline-block">
          Powered by
          <img
            width="140"
            class="d-inline-block"
            src="../assets/yesbanklogo.svg"
            alt="appreciate-logo"
          />
        </p>
      </div>
    </section>

    <!-- if vkyc is intiated or some errors -->
    <section class="vkyc-content" v-if="kyc_status">
      <h1 class="vkyc-content-heading">{{ kyc_message }}</h1>
      <p>
        That was fast! Our records indicate that you have already submitted your
        VKYC to a YES Bank agent. Pkease look out for any follow-up messages on
        the Appreciate app related to your account or VKYC.
      </p>
      <div class="mt-4 align-left">
        <p class="d-inline-block">
          Powered by
          <img
            width="140"
            class="d-inline-block"
            src="../assets/yesbanklogo.svg"
            alt="appreciate-logo"
          />
        </p>
      </div>
    </section>

    <!-- vkyc non availabilty hours -->
    <md-dialog :md-active.sync="isAvailable">
      <md-dialog-content>
        <img width="100px" class="d-block m-auto" src="../assets/announce.svg" alt="">

        <h4 class="font-weight-bold fs-18 text-center">
          <b>Currently, VKYC is unavailable</b>
        </h4>

        <div class="time-card">
          <h5 class="fs-16"><b> VKYC Timings:</b></h5>
          <div v-for="item in vkycTiming.timings">
            <div class="d-flex justify-content-between pb-2">
              <div>
                <img class="clock-icon" src="../assets/clock.svg" alt=""> 
                <p class="d-inline">{{ item.days }}</p>
               </div>
              <div>
               <b>{{ item.time }}</b> 
              </div>
            </div>
          </div>
          <p>bank holidays excluded</p>
        </div>

        <md-button @click="isAvailable = false" class="btn-clr w-100 m-auto">okay</md-button>

      </md-dialog-content>
    </md-dialog>

    <!-- snackbar messages -->
    <md-snackbar style="background-color:#cc2525;" :md-position="position" :md-duration="isInfinity ? Infinity : duration"
      :md-active.sync="showSnackbar" md-persistent>
      <span>{{ error_message }}</span>
    </md-snackbar>
  </div>
</template>
<script>
import config from "@/config";

export default {
  data() {
    return {
      kyc_status: false,
      kyc_message: "",
      showSnackbar: false,
      position: "center",
      duration: 4000,
      isInfinity: false,
      error_message: "",
      isAvailable: false,
      vkycTiming: []
    };
  },

  methods: {
    checkVKYC() {

      console.log(this.kycDetails);
      let vm = this;
      axios
        .get(`${config.apiUrl}/?id=${this.$route.params.id}`)
        .then((res) => {
          this.kyc_message = res.data.message;
          //if kyc link redirect and return
          if (res.data.status && res?.data?.data?.vkyc_link) {
            window.open(res.data.data.vkyc_link, '_self');
            return;
          } else if (res.data.error_status.error_code == '90004' || res.data.error_status.error_code == '90005') {
            vm.showSnackbar = !vm.showSnackbar;
            vm.error_message = res.data.message;
            return;
          } else if (res.data.error_status.error_code == '10043') {
            vm.isAvailable = true;
            vm.vkycTiming = res.data.data
            return;
          } else {
            this.kyc_status = true;
            this.kyc_message = res.data.message;
          }
        })
        .catch(function (error) {
          if (error.response) {
            vm.showSnackbar = !vm.showSnackbar;
            vm.error_message = error.response.data.message;
          } else {
            vm.kyc_status = true;
          }
        });
    },
  },
};
</script>

<style lang="scss">
.content-bullet {
  width: 6px;
  height: 6px;
  background: #fade6d;
  border-radius: 50%;
  display: inline-block;
  margin: auto;
  padding: 5px;
  margin-right: 4px;
}

.main-container {
  background-image: url("../assets/vkyclanding.svg");
  text-align: center;
  height: 100vh;
  background-attachment: fixed;
  background-position: right bottom;
  background-repeat: no-repeat;
}

.vkyc-landing {
  text-align: left;

  .vkyc-landing-logo {
    width: 150px;
    margin: 30px;
  }
}

.btn-clr {
  background-color: #0461e5 !important;
  color: white !important;
  padding: 0 13px !important;
  text-transform: none !important;
}

.start-button {
  text-transform: none;
}

.content-list {
  line-height: 2;
}

.vkyc-content {
  text-align: left;
  // margin: 0 30% 0 15%;
  padding: 5% 20%;

  .vkyc-content-heading {
    font-weight: 800;
    // font-size: 46px;
    line-height: 140%;
    color: #012c79;
  }
}

.time-card {
  box-shadow: 0px 0px 16px rgba(6, 16, 88, 0.1);
  padding: 20px;
  margin: 20px 0;
}

.md-dialog-container{
  width: 400px;
  border-radius: 15px !important;
}

.clock-icon{
  vertical-align: sub;
  margin-right: 5px;
}

@media only screen and (max-width: 768px) {
  .vkyc-content {
    padding: 5% 6%;
  }
}
</style>
