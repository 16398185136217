<template >
  <div class="home">
    <nav
      class="navbar navbar-expand-lg navbar-fixed-top about-nav-color"
      style="background: white"
      id="nav"
    >
      <a class="navbar-brand" href="https://appreciate.pacewisdom.in/index.php"
        ><img
          src="../assets/logo.svg"
          class="black-text-logo"
          id="black-text-logo"
      /></a>
    </nav>
    <div v-if="errorMgs">
      <h2 style="color: red">{{ errorMgs }}</h2>
    </div>
    <div v-else>
      <h2 style="color: green">{{ message }}</h2>
    </div>
    <section class="emailsection">
      <p
        style="
          display: table;
          text-align: center;
          margin-left: auto;
          margin-right: auto;
          color: blue;
        "
        v-if="message"
      >
        Redirect In - {{ timerCount }} Sec
      </p>
    </section>
  </div>
</template>
<script>
import config from "@/config";
export default {
  data() {
    return {
      message: "",
      errorMgs: "",
      timerCount: 5,
    };
  },

  created() {
    let vm = this;
    axios
      .get(
        `${config.apiUrl}/?id=${this.$route.params.id}&users=${this.$route.params.users}&email_id=${this.$route.params.email_id}`
      )
      .then(function (response) {
        if(response.status){
          vm.message = response.data.message;
         setTimeout(function () {
          window.location.href = response.data.data.redirect_to;
        }, 5000);
        }else{
          vm.errorMgs = response.data.message;
        }
      })
      .catch(function (error) {
        vm.errorMgs = error.response.data.message;
        throw error;
      });
  },
  watch: {
    timerCount: {
      handler(value) {
        if (value > 0) {
          setTimeout(() => {
            this.timerCount--;
          }, 1000);
        }
      },
      immediate: true,
    },
  },
};
</script>
<style>
.emailsection {
  height: 55vh;
  background-image: url(../assets/banner-pattern.svg);
  background-position: bottom;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-size: contain;
}
</style>
