export default {
    apiUrl: 'http://13.235.156.97:5008/v1',
    emailApiUrl:'http://13.235.156.97:5035/v1',
    secretKey: "fVBRkkXRM2hNnGJj",
    poaStatus: {
        url: 'https://b2b-kyc-dev.pacewisdom.in/api/v1/kyc/get_poa_status/',
        partnerKey: 'appreciate',
        kycRefNo: 'KYCZNNFj9k1rARFgzA48'
    }

}