<template>
  <div class="main-container d-flex">
    <div class="box">
      <img src="../assets/appreciateLogoHorizontal.png" class="black-text-logo" id="black-text-logo" />
      <h2 class="fs-5 mt-3">Reset Your Password</h2>
      <div class="">
        <div v-if="errorMgs">
          <h2 style="color:red; font-size:16px;">{{ errorMgs }}</h2>
        </div>
        <div v-else>
          <h2 style="color:green; font-size:16px;">{{ message }}</h2>
        </div>
        <form novalidate class="" @submit.prevent="validateUser">
          <div class=" mt-3">
            <md-field :class="getValidationClass('password')">
              <label>Password</label>
              <md-input @keydown="onKeydownMain($event)" @blur="$v.form.password.$touch()" @copy.prevent @paste.prevent
                type="password" v-model="form.password" placeholder="Enter Password" required></md-input>

              <div class="d-block">
                <span class="md-error mb-1" v-if="!$v.form.password.required">Password is required</span>
                <!-- <span class="md-error" v-else-if="!$v.form.password.minLength">Password should be more than 8
                  characters.</span>
                <span class="md-error" v-else-if="!$v.form.password.uppercase">Password should contain one uppercase
                  character.</span>
                <span class="md-error" v-else-if="!$v.form.password.lowercase">Password should contain one lowercase
                  character.</span>
                <span class="md-error" v-else-if="!$v.form.password.specialChar">Password should contain special
                  character.</span> -->
                <!-- <span class="md-error" v-else-if="!$v.form.password.digits">Password should contain numbers.</span> -->
              </div>
            </md-field>
            <!-- :src="!$v.form.password.uppercase ? '../assets/appreciateLogoHorizontal.png' : '../assets/appreciateLogoHorizontal.png'" -->
            <div class="password-val">
              <div class="d-flex" style="align-items:start;justify-content:left">
                <div>
                  <img class="check-box-icon" :src="$v.form.password.uppercase && $v.form.password.lowercase
                      ? require('../assets/circle-check-box.svg')
                      : require('../assets/circle-icon.svg')
                    " alt="check-box" />
                </div>
                <div>
                  <p :class="$v.form.password.uppercase && $v.form.password.lowercase
                      ? 'text-green'
                      : ''
                    " style="padding-left:6px">
                    Atleast 1 upper & lower case alphabet
                  </p>
                </div>
              </div>
              <div class="d-flex" style="align-items:start;justify-content:left">
                <div>
                  <img class="check-box-icon" :src="$v.form.password.digits
                      ? require('../assets/circle-check-box.svg')
                      : require('../assets/circle-icon.svg')
                    " alt="check-box" />
                </div>
                <div>
                  <p :class="$v.form.password.digits ? 'text-green' : ''" style="padding-left:6px">
                    Atleast 1 number
                  </p>
                </div>
              </div>

              <div class="d-flex" style="align-items:start;justify-content:left">
                <div>
                  <img class="check-box-icon" :src="$v.form.password.specialChar
                      ? require('../assets/circle-check-box.svg')
                      : require('../assets/circle-icon.svg')
                    " alt="check-box" />
                </div>
                <div>
                  <p :class="$v.form.password.specialChar ? 'text-green' : ''" style="padding-left:6px">
                    Atleast 1 special character (@,$,!,%,*,#,?,&)
                  </p>
                </div>
              </div>
              <div class="d-flex" style="align-items:start;justify-content:left">
                <div>
                  <img class="check-box-icon" :src="form.password.length >= 8
                      ? require('../assets/circle-check-box.svg')
                      : require('../assets/circle-icon.svg')
                    " alt="check-box" />
                </div>
                <div>
                  <p :class="form.password.length >= 8 ? 'text-green' : ''" style="padding-left:6px">
                    Minimum of 8 characters
                  </p>
                </div>
              </div>

              <div class="d-flex" style="align-items:start;justify-content:left">
                <div>
                  <img class="check-box-icon" :src="require('../assets/circle-check-box.svg')" alt="check-box" />
                </div>

                <div>
                  <p class="text-green" style="padding-left:6px">
                    Last 3 passwords are restricted
                  </p>
                </div>
              </div>

            </div>
            <!-- <password-meter :password="form.password" @score="onScore" /> -->
          </div>
          <div class="mb-3 mt-3">
            <md-field :class="getValidationClass('confirm_password')">
              <label>Password</label>
              <md-input @keydown="onKeydownMain($event)" @blur="$v.form.confirm_password.$touch()" type="password"
                v-model="form.confirm_password" placeholder="Enter Confirm Password" required></md-input>
              <span class="md-error" v-if="!$v.form.confirm_password.required">Confirm password is required</span>
              <span class="md-error" v-else-if="!$v.form.confirm_password.sameAsPassword">Password must match</span>
            </md-field>
          </div>

          <md-button :disabled="$v.$invalid" type="submit" class="md-raised  md-primary w-100 mx-0">Set
            Password</md-button>
        </form>
      </div>
    </div>
    <div></div>
  </div>
</template>
<script>
import passwordMeter from "vue-simple-password-meter";
import config from "@/config";
import { validationMixin } from "vuelidate";
import { required, sameAs, minLength } from "vuelidate/lib/validators";
export default {
  mixins: [validationMixin],
  components: { passwordMeter },
  data() {
    return {
      initial: "Initial Value",
      message: "",
      errorMgs: "",
      score: null,
      passwordInfo: false,
      form: {
        password: "",
        confirm_password: "",
      },
      isSpecialCharacter: false,
      isUppercaseCharacter: false,
      isLowecase: false,
      isDigits: false,
      notAllowedChar: false,
    };
  },
  validations: {
    form: {
      password: {
        required,
        minLength: minLength(8),
        uppercase: (value) => /[A-Z]/.test(value),
        lowercase: (value) => /[a-z]/.test(value),
        specialChar: (value) => /[`!@#$%&*?]/.test(value),
        digits: (value) => /[0-9]/.test(value),
      },
      confirm_password: {
        required,
        sameAsPassword: sameAs("password"),
      },
    },
  },
  methods: {
    getValidationClass(fieldName) {
      const field = this.$v.form[fieldName];

      if (field) {
        return {
          "md-invalid": field.$invalid && field.$dirty,
        };
      }
    },
    validateUser() {
      this.$v.$touch();
      if (
        !this.$v.$invalid &&
        !this.passwordInfo &&
        this.form.password.length >= 8
      ) {
        this.resetPassword();
      }
    },

    /**
     * prevents user from typing these characters
     * @param {*} event
     */
    onKeydownMain(event) {
      if (event.which == 32) {
        event.preventDefault();
      }
      let key = ['~', '`', '-', '^', '(', ')', '-', '_', '+', '=', '{', '}', '[', ']', '|', "\\", '/', ',', ':', ';', '"', '.', "'", '>', '', '<']
      if (key.includes(event.key)) {
        event.preventDefault();
      }
    },
    resetPassword() {
      let vm = this;
      axios
        .post(`${config.apiUrl}/?id=${this.$route.params.id}`, this.form)
        .then((res) => {
          console.log();
          //Perform Success
          if (res.status) {
            vm.message = res.data.message;
            vm.errorMgs = "";
          } else {
            vm.message = "";
            vm.errorMgs = res.data.message;
          }
        })
        .catch(function (error) {
          if (error.response.data.type == "validation_error") {
            if (error.response.data.data.password) {
              vm.errorMgs = error.response.data.data.password;
            } else {
              vm.errorMgs = error.response.data.message;
            }
          } else {
            vm.errorMgs = error.response.data.message;
          }
          throw error;
        });
    },

    /**
     * set the password meter message
     */
    onScore({ score, strength }) {
      console.log(this.form.password.length); // from 0 to 4
      console.log("score", strength); // one of : 'risky', 'guessable', 'weak', 'safe' , 'secure'
      const specialChars = /[`!@#$%&*?]/;
      const captChars = /[A-Z]/;
      const smallLetter = /[a-z]/;
      const digits = /[0-9]/;
      const notAllowedCha = /[(^)_+\-=\[\]{};':"\\|,.<>\/]/;

      // @$!%*#?&
      this.isSpecialCharacter = specialChars.test(this.form.password);
      this.isUppercaseCharacter = captChars.test(this.form.password);
      this.isLowecase = smallLetter.test(this.form.password);
      this.isDigits = digits.test(this.form.password);
      this.notAllowedChar = notAllowedCha.test(this.form.password);
      //not allowed char pasted
      if (this.notAllowedChar) {
        this.passwordInfo = true;
        this.score = 2;
      }
      //password should contain 8 characters
      else if (this.form.password.length < 8) {
        this.passwordInfo = true;
        this.score = 2;
        return;
      }

      //atleast one special character
      else if (!this.isSpecialCharacter) {
        this.passwordInfo = true;
        this.score = 2;
      }

      //atleast one uppercase
      else if (!this.isUppercaseCharacter) {
        this.passwordInfo = true;
        this.score = 2;
        //atleast one lowercase
      } else if (!this.isLowecase) {
        this.passwordInfo = true;
        this.score = 2;
        //atleast one digit
      } else if (!this.isDigits) {
        this.passwordInfo = true;
        this.score = 2;
      } else if (score < 2) {
        this.passwordInfo = true;
      } else {
        this.passwordInfo = false;
        this.score = 4;
      }

      // if (score <= 3 && this.form.password.length < 7) {
      //   this.score = score;
      //   this.passwordInfo = true
      // } else {
      //   this.passwordInfo = false
      // }
    },
  },
};
</script>

<style lang="scss" scoped>
$color: #0461e5;

/* Scoped CSS for this component */
body {
  background: #fbfbfb;
}

.btn-clr {
  background-color: $color;
}

.check-box-icon {
  width: 20px !important;
  height: 20px !important;
}

.password-val {
  font-size: 12px;
  text-align: left;
  background: #f6f6f6;
  border-radius: 0px 0px 12px 12px;
  padding: 20px 14px;
}

.main-container {
  background-image: url("../assets/loginbackground.svg");
  background-size: cover;
  text-align: center;
  height: 100vh;
  background-attachment: fixed;
  background-position: 100% 0;
}

.box img {
  width: 100%;
  max-width: 250px;
  height: 70px;
  mix-blend-mode: multiply;
  object-fit: contain;
}

.d-flex {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.title {
  display: flex;
}

.strong-label {
  margin-right: 10px;
  font-weight: 900;
}

.icon-rtl {
  padding-right: 32px;
  background-size: 20px;
}

.md-field {
  margin: 0 !important;
}

/* .text-black{
col
} */

.text-green {
  color: #00b871;
}

.box {
  width: 438px;
  position: relative;
  margin: 15px;
  padding: 32px 24px 40px;
  background: #fff;
  box-shadow: 0 0 44px -5px #06105821;
  border-radius: 16px;
}
</style>
