<template>
  <section class="center-align">
    <h1 v-if="!$route.params.email_id">You've Unsubscribed Successfully!</h1>
    <div v-if="$route.params.email_id">
      <h1>We're sad to see you go!</h1>
      <h5>Are you sure you want to unsubscribe?</h5>
      <button @click="unsubscribe()" class="blue-btn" type="submit">Unsubscribe</button>
    </div>
    <md-snackbar style="background-color:#cc2525;" :md-position="position"
      :md-duration="isInfinity ? Infinity : duration" :md-active.sync="showSnackbar" md-persistent>
      <span>{{ error_message }}</span>
    </md-snackbar>
  </section>
</template>
<script>
import config from "@/config";

export default {
  data() {
    return {
      kyc_status: false,
      kyc_message: "",
      showSnackbar: false,
      position: "center",
      duration: 4000,
      isInfinity: false,
      error_message: "",
    }
  },
  methods: {
    unsubscribe() {
      let vm = this;
      axios
        .post(`${config.emailApiUrl}/unsubscribe-user/?email_id=${this.$route.params.email_id}&group_id=${this.$route.params.group_id}&reason=testing`, {}, {
          headers: {
            "secret-key": config.secretKey
          }
        })
        .then((res) => {
          console.log("res", res);
          if (res.data.status) {
            this.$router.push({ name: 'Unsubscribe-success' });
            return;
          } else {
            this.kyc_message = res.data.message;
          }
        })
        .catch(function (error) {
          if (error.response) {
            vm.showSnackbar = !vm.showSnackbar;
            vm.error_message = error.response.data.message;
          } 
        });
    }

  }
}
</script>
<style>
.center-align {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-image: url("../assets/vkyclanding.svg");
  text-align: center;
  background-attachment: fixed;
  background-position: right bottom;
  background-repeat: no-repeat;
}

.blue-btn {
    margin: 12px auto;
    padding: 14px 33px;
    outline: none;
    border: none;
    color: white;
    font-weight: 500;
    background-color: #0461e5;
    transition-duration: 0.4s;
    font-size: 16px;

}

.blue-btn:hover {
  transition-duration: 0.1s;
  background-color: #539aff;
}
</style>